/* import __COLOCATED_TEMPLATE__ from './salesforce.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneDeployOverviewSalesforce extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig() {
    return this.finStandaloneService.salesforceConfig!;
  }

  get phoneStatus() {
    return IntegrationState.TestMode;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Deploy::Overview::Salesforce': typeof StandaloneDeployOverviewSalesforce;
    'standalone/deploy/overview/salesforce': typeof StandaloneDeployOverviewSalesforce;
  }
}
